<template>
  <div>
    <BaseTableHeader
      @searchChange="searchChange"
      @refresh="refresh"
    />

    <BaseTable
      :headers="headers"
      :items="users"
      :search="search"
      tableKey="id"
      rowKey="id"
      @rowClick="rowClick"
    />

  </div>
</template>

<script>
import { headers } from '@/components/config/users';
import baseTableMixin from '@/components/mixins/workspace/baseTableMixin';

export default {
  name: 'UserTable',
  mixins: [baseTableMixin],
  props: {
    users: Array,
  },
  data: () => ({
    headers,
  }),
  methods: {
    viewAlerts(user) {
      this.$emit('viewAlerts', user);
    },
  },
  mounted() {
    const ct = this.viewAlerts;
    const alertHeader = headers.find(header => header.value === 'icon');

    if (alertHeader) {
      alertHeader.format = (item, index, createElement) => (
        createElement('span', {
          on: {
            click: () => {
              ct(item);
            },
          },
          class: 'ap-alert-pointer',
        }, 'VIEW')
      );
    }
  },
};
</script>
